<template>
	<div class="wrap">
		<el-tabs v-model="activeName">
			<el-tab-pane name="second">
				<span slot="label">
					<i class="el-icon-notebook-2"></i> 自营套餐
				</span>
				<el-alert title="操作提示" type="success" class="margin-bottom">
					<div>
						1. 该页面展示了小店所有的套餐信息，可对套餐进行编辑修改操作。<br />
						2. 商家添加的自营套餐，不会出现在小程序端，能提供线下收银端使用。
					</div>
				</el-alert>
				<my-self></my-self>
			</el-tab-pane>
			<el-tab-pane name="first">
				<span slot="label">
					<i class="el-icon-notebook-2"></i> 品牌套餐
				</span>
				<el-alert title="操作提示" type="success" class="margin-bottom">
					<div>
						1. 该页面展示了小店所有的套餐信息，可对套餐进行编辑修改操作。<br />
						2. 商家添加的自营套餐，不会出现在小程序端，能提供线下收银端使用。
					</div>
				</el-alert>
				<my-brand></my-brand>
			</el-tab-pane>
			
		</el-tabs>
	</div>
</template>
<script>
	import mySelf from './children/MySelf.vue'
	import myBrand from './children/MyBrand.vue'
	export default {
		components: {
			mySelf,
			myBrand
		},
		data() {
			return {
				activeName: 'second',
			};
		},
	};
</script>

<style scoped lang="scss">

</style>
