<template>
	<div>
		<div class="flex justify-between">
			<el-form :inline="true" :model="searchForm">
				<el-form-item label="套餐名称">
					<el-input v-model="searchForm.name" placeholder="套餐名称"></el-input>
				</el-form-item>
				<el-form-item label="品牌">
					<el-select v-model="searchForm.serve_brand_id" placeholder="全部" clearable @change="search()">
						<el-option v-for="item in brands" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search()">查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-table :data="tableData" v-loading="loading">
			<el-table-column type="index" width="50">
			</el-table-column>
			<el-table-column prop="name" label="套餐名称" width="280">
				<template slot-scope="scope">
					<div class="flex align-center">
						<el-image v-if="scope.row.cover" class="cover" :src="scope.row.cover" fit="cover"></el-image>
						{{scope.row.name}}
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="status" label="状态">
				<template slot-scope="scope">
					<div class="text-green" v-if="scope.row.status">正常</div>
					<div class="text-gray" v-else>停用</div>
				</template>
			</el-table-column>
			<el-table-column fixed="right" label="操作" width="150">
				<template slot-scope="scope">
					<el-button @click="publish(scope.row)" size="mini" v-if="scope.row.status" type="danger">停用</el-button>
					<el-button @click="publish(scope.row)" size="mini" v-else type="success">启用</el-button>
					<!-- <el-popconfirm title="确定删除吗？" @confirm="del(scope.row)">
						<el-button slot="reference" size="mini">删除</el-button>
					</el-popconfirm> -->
				</template>
			</el-table-column>
		</el-table>
		<div class="text-center margin-top">
			<el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="vuex_page_sizes" :page-size="searchForm.page_size"
			layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="pageSizeChange">
			</el-pagination>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				loading: false, // 表格加载状态
				tableData: [], // 数据
				brands:[],// 品牌
				searchForm: {
					serve_brand_id: '',
					type: 2, // 1 自营 2 品牌
					name: '', // 否 string 产品名称
					page: 1, // 否 int 页码
					page_size: 10, // 否 int 页脚
				},
				total: 0,
			};
		},
		mounted() {
			this.$api.common.getBrandList().then(res=>{
				this.brands = res.data
			})
			// 查询数据
			this.search()
		},
		methods: {
			search() {
				this.loading = true;
				this.$api.group.getGroups(this.searchForm).then(res => {
					this.total = res.data.count;
					this.tableData = res.data.data;
					this.loading = false;
				})
			},
			pageSizeChange(size) {
				this.searchForm.page_size = size;
				this.search()
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.search()
			},
			publish(item) {
				let status = item.status;
				item.status = item.status == 1 ? 0 : 1;
				this.$api.group.publishBrandGroup({
					id: item.id,
					status: item.status
				}).then(res => {
					if (res.code == 200) {
						this.$message.success('操作成功')
					} else {
						this.$message.error(res.msg)
						item.status = status;
					}
				}).catch(err => {
					item.status = status;
					this.$alert(err)
				})
			}
		},
	};
</script>

<style scoped lang="scss">
	.cover {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		overflow: hidden;
		margin-right: 10px;
	}
</style>
